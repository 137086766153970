exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cartoontengoku-js": () => import("./../../../src/pages/cartoontengoku.js" /* webpackChunkName: "component---src-pages-cartoontengoku-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-studies-js": () => import("./../../../src/pages/studies.js" /* webpackChunkName: "component---src-pages-studies-js" */),
  "component---src-pages-work-1-plus-31-2-js": () => import("./../../../src/pages/work/1plus31_2.js" /* webpackChunkName: "component---src-pages-work-1-plus-31-2-js" */),
  "component---src-pages-work-1-plus-31-js": () => import("./../../../src/pages/work/1plus31.js" /* webpackChunkName: "component---src-pages-work-1-plus-31-js" */),
  "component---src-pages-work-4-js": () => import("./../../../src/pages/work/4.js" /* webpackChunkName: "component---src-pages-work-4-js" */),
  "component---src-pages-work-atopic-js": () => import("./../../../src/pages/work/atopic.js" /* webpackChunkName: "component---src-pages-work-atopic-js" */),
  "component---src-pages-work-connected-cg-js": () => import("./../../../src/pages/work/connected_CG.js" /* webpackChunkName: "component---src-pages-work-connected-cg-js" */),
  "component---src-pages-work-connected-js": () => import("./../../../src/pages/work/connected.js" /* webpackChunkName: "component---src-pages-work-connected-js" */),
  "component---src-pages-work-draw-lines-and-drawing-js": () => import("./../../../src/pages/work/drawLinesAndDrawing.js" /* webpackChunkName: "component---src-pages-work-draw-lines-and-drawing-js" */),
  "component---src-pages-work-funny-doll-1000-js": () => import("./../../../src/pages/work/funnyDoll1000.js" /* webpackChunkName: "component---src-pages-work-funny-doll-1000-js" */),
  "component---src-pages-work-grids-js": () => import("./../../../src/pages/work/grids.js" /* webpackChunkName: "component---src-pages-work-grids-js" */),
  "component---src-pages-work-installation-view-js": () => import("./../../../src/pages/work/installationView.js" /* webpackChunkName: "component---src-pages-work-installation-view-js" */),
  "component---src-pages-work-lines-and-colors-js": () => import("./../../../src/pages/work/linesAndColors.js" /* webpackChunkName: "component---src-pages-work-lines-and-colors-js" */),
  "component---src-pages-work-old-works-js": () => import("./../../../src/pages/work/oldWorks.js" /* webpackChunkName: "component---src-pages-work-old-works-js" */),
  "component---src-pages-work-other-drawings-js": () => import("./../../../src/pages/work/otherDrawings.js" /* webpackChunkName: "component---src-pages-work-other-drawings-js" */),
  "component---src-pages-work-super-compressed-literary-illustration-js": () => import("./../../../src/pages/work/superCompressedLiteraryIllustration.js" /* webpackChunkName: "component---src-pages-work-super-compressed-literary-illustration-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */)
}

