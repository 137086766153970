require("./src/styles/global.css")
const React = require("react")

let firstEnter = true;

function LangAndSizeProvider({ children }) {
    function getSize() {
        return {
            width: window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth,
            height: window.innerHeight
                || document.documentElement.clientHeight
                || document.body.clientHeight,
        };
    }

    function getLanguage() {
        let language = localStorage.getItem("site_Language") || window.navigator.language.slice(0, 2) || "en"
        return language;
    }

    const lang = getLanguage();
    const size = getSize();

    return (
        <div
            lang={lang}
            size={size}
        >
            {children}
        </div>
    )
}


exports.wrapRootElement = ({ element }) => {
    //console.log("wrapRootElement");//test
    return <LangAndSizeProvider>{element}</LangAndSizeProvider>
}


exports.onInitialClientRender = () => {
    //console.log("onInitialClientRender");//test
    //console.log(`firstEnter is ${firstEnter}`);//test
    const elements = document.querySelectorAll("#___loader");
    for (let i = 0, len = elements.length; i < len; i++) {
        setTimeout(function () {
            elements[i].style.opacity = 0;
            //console.log("done1");//test
        }, 500)
        setTimeout(function () {
            elements[i].style.display = "none";
            //console.log("done2");//test
        }, 1000)
    }
    
    setTimeout(function () {
        firstEnter = false;
        //console.log("done3");//test
    }, 1000)
}

exports.onRouteUpdate = () => {
    //console.log("onRouteUpdate");//test
    //console.log(`firstEnter is ${firstEnter}`);//test
    const elements = document.querySelectorAll("#___loader");
    for (let i = 0, len = elements.length; i < len; i++) {
        if (!firstEnter) elements[i].style.display = "none";
    }
}

